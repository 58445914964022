import ContractSerialNestedForm from "stimulus-rails-nested-form"

export default class extends ContractSerialNestedForm {

  connect() {
    super.connect()
    // console.log("ContractSerialNestedForm: Do what you want here.")
  }

  add (e) {
    e.preventDefault()

    const content = this.templateTarget.innerHTML.replace(/NEW_SERIAL/g, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }
}
