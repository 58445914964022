import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item", "quantity", "costprice", "totalcost", "pretaxtotal", "taxamount", "totalpurchases", "taxpercentage" ]

  connect() {
    //this.element.textContent = "Hello World!"
    // console.log("Purchases")
    // console.log("Do what you want here.")
    
    // $('#client_id').select2();
    // $('#sponsor_id').select2();
    this.calculate_item_total()
  }

  taxtpercentchanged() {
    this.calculate_item_total()
  }

  itemchanged() {
    this.calculate_item_total()
  }

  quantitychanged() {
    // console.log("Quantity changed")
    this.calculate_item_total()
  }

  costpricechanged() {
    // console.log("Cost Price changed")
    this.calculate_item_total()
  }

  calculate_item_total() {
    var prices = document.querySelectorAll("[id^=cost_price_]")
    var quantities = document.querySelectorAll("[id^=quantity_]")

    var totals = 0

    for (var i = 0, len = prices.length; i < len; i++) {
      totals += parseFloat(prices[i].value) * parseFloat(quantities[i].value)
    }

    this.pretaxtotalTarget.value = totals

    if (this.hasTaxamountTarget) {
      // console.log("taxamount: " + this.taxamountTarget.value)
      this.taxamountTarget.value = totals * parseFloat(this.taxpercentageTarget.value) / 100
      this.totalpurchasesTarget.innerHTML = totals + totals * parseFloat(this.taxpercentageTarget.value) / 100
      // this.totalamountTarget.value = totals + parseFloat(this.taxamountTarget.value) - parseFloat(this.downpaymentamountTarget.value)
    }
    else {
      // this.totalamountTarget.value = totals
      this.totalpurchasesTarget.innerHTML = totals + totals * parseFloat(this.taxpercentageTarget.value) / 100
    }
  }

}
