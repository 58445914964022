import { Application } from "@hotwired/stimulus"
const application = Application.start()

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js or *_controller.ts.
// import { Application } from "@hotwired/stimulus"
// const application = Application.start()

// import { Turbo } from "@hotwired/turbo-rails"
// window.Turbo = Turbo

// // Configure Stimulus development experience
// application.debug = false
// window.Stimulus   = application

import Clipboard from '@stimulus-components/clipboard'
import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)
application.register('clipboard', Clipboard)

// import('select2')
// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js or *_controller.ts.
// import { Application } from "@hotwired/stimulus"
// const application = Application.start()

// import { Turbo } from "@hotwired/turbo-rails"
// window.Turbo = Turbo

// // Configure Stimulus development experience
// application.debug = false
// window.Stimulus   = application

// import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
// application.register('alert', Alert)
// application.register('autosave', Autosave)
// application.register('dropdown', Dropdown)
// application.register('modal', Modal)
// application.register('tabs', Tabs)
// application.register('popover', Popover)
// application.register('toggle', Toggle)
// application.register('slideover', Slideover)

// export { application } 
