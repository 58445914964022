import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="disable-button"
export default class extends Controller {
  static values = { with: String }

  connect() {
    // console.log("disable")
    this.element.dataset['action'] = 'submit->disable-button#disableForm'
    
    if (!this.hasWithValue) {
      this.withValue = "Processing..."
    }
  }

  disableForm() {
    this.submitButtons().forEach(button => {
      button.disabled = true
      button.value = this.withValue
    })
  }

  submitButtons() {
    return this.element.querySelectorAll("input[type='submit']")
  }
}
