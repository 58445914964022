import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "client", "contracts", "form" ]
  static values = {
    url: String,
    param: String
  }

  connect() {
    $('#client_id').select2();

    $("#client_id").on('select2:select', function () {
      //console.log("list item selected");
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });

    // console.log(this.hasFormTarget)
  }

  clientchanged(event) {
    this.formTarget.requestSubmit()
    // let params = new URLSearchParams()
    // params.append(this.paramValue, event.target.selectedOptions[0].value)

    // get(`${this.urlValue}?${params}`, {
    //   responseKind: "turbo-stream"
    // })
  }
}
