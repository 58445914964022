import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="side-menu-expand"
export default class extends Controller {
  // static targets = [ "headerbutton", "button", "up", "down" ]

  connect() {
    // console.log("side menu expand")
    // this.checkCookie(this.headerbuttonTarget.dataset.cname, true)
    // console.log(this.headerbuttonTarget.dataset.cname)
  }

  toggle() {
    // console.log("toggle!")
    let sidebar_button = document.getElementById("main_sidemenu")
    let expanded = sidebar_button.getAttribute('aria-expanded')

    sidebar_button.classList.toggle("min-w-fit")
    sidebar_button.classList.toggle("sm:flex")
    // console.log("Done!")

    // if (expanded == 'true') {
    //   sidebar_button.setAttribute('aria-expanded','false')
    // } else {
    //   sidebar_button.setAttribute('aria-expanded','true')
    // }
  }

}
