import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "client", "costamount", "requiredamount", "downpaymentamount", "pretaxtotal", "aftertaxtotal", "taxamount", "totalamount", "numberofinstallmentsamount", "firstinstallmentamount", "lawyerfeeincluded", "lawyerfeeamount", "taxpercentage", "quantity", "salesamount", "totalsales", "item", "select" ]

  connect() {
    if (this.haslawyerfeeamountTarget) 
    {
      this.lawyerfeeamountTarget.readOnly = !this.lawyerfeeincludedTarget.checked
    }

    // this.selectTargets.forEach(element => {
    //   new TomSelect(element, this.config())
    // });
    // new TomSelect($('#open_contract_client_id'), this.config())
    // new TomSelect($('#open_contract_payment_type_id'), this.config())
    // new TomSelect($('#open_contract_sponsor_id'), this.config())

    // if found $('#contract_number_of_installments'), initialize tom-select
    // new TomSelect($('#contract_number_of_installments'), this.config())

    // $('#open_contract_client_id').select2();
    // $('#open_contract_payment_type_id').select2();
    // $('#open_contract_sponsor_id').select2();

    // $('#contract_client_id').select2();
    // $('#contract_sponsor_id').select2();

    // $('#contract_branch_id').select2();
    // $('#contract_investor_id').select2();
    // $('#contract_employee_id').select2();

    this.recalculate_totalamount()
  }

  taxpercentchanged() {
    this.recalculate_totalamount()
  }
  
  itemchanged() {
    this.recalculate_totalamount()
  }
  
  quantitychanged() {
    // console.log("Quantity changed")
    // this.calculate_item_total()
    this.recalculate_totalamount()
  }

  salesamountchanged() {
    // console.log("Cost Price changed")
    // this.calculate_item_total()
    this.recalculate_totalamount()
  }

  clientchanged() {
  }

  costamountchanged()
  {
    // this.recalculate_required_amount()
  }

  downpaymentchanged()
  {
    this.recalculate_totalamount()
  }

  totalamountchanged()
  {
    this.recalculate_totalamount()
  }

  pretaxchanged()
  {
    this.recalculate_totalamount()
  }

  recalculate_totalamount()
  {
    // console.log("contract recalculate_totalamount")
    // console.log("hasTax: " + this.hasTaxamountTarget)
    if (this.hasTaxamountTarget) {
      if ((this.pretaxtotalTarget.value) && (this.downpaymentamountTarget.value) && (this.numberofinstallmentsamountTarget.value))
      {
        // console.log("taxamount: " + this.taxamountTarget.value)
        this.taxamountTarget.value = parseFloat(this.pretaxtotalTarget.value) * parseFloat(this.taxpercentageTarget.value) / 100
        this.totalamountTarget.value = parseFloat(this.pretaxtotalTarget.value) + parseFloat(this.taxamountTarget.value) - parseFloat(this.downpaymentamountTarget.value)
        this.firstinstallmentamountTarget.value = this.calculatefirstinstallment(this.totalamountTarget.value, this.numberofinstallmentsamountTarget.value)
        this.aftertaxtotalTarget.innerHTML = parseFloat(this.taxamountTarget.value) + parseFloat(this.pretaxtotalTarget.value)
      }
    }
    else {
      if ((this.pretaxtotalTarget.value) && (this.downpaymentamountTarget.value) && (this.numberofinstallmentsamountTarget.value))
      {
        this.totalamountTarget.value = parseFloat(this.pretaxtotalTarget.value) - parseFloat(this.downpaymentamountTarget.value)
        this.firstinstallmentamountTarget.value = this.calculatefirstinstallment(this.totalamountTarget.value, this.numberofinstallmentsamountTarget.value)
      }
    }
  }

  numberofinstallmentschanged()
  {
    this.recalculate_totalamount()
  }

  firstinstallmentchanged()
  {
    this.recalculate_totalamount()
  }

  recalculate_required_amount()
  {
    // this.requiredamountTarget.value = this.costamountTarget.value - this.downpaymentamountTarget.value
    // this.totalamountTarget.min = this.requiredamountTarget.value

    // this.totalamountTarget.value = (parseFloat(this.pretaxtotalTarget.value) + parseFloat(this.taxamountTarget.value)) - parseFloat(this.downpaymentamountTarget.value)
  }

  calculatefirstinstallment(amount, installments)
  {
    if (installments > 0) {
      return parseInt(amount/installments)
    } else {
      return 0
    }
  }

  lawyerfeeincludedchanged()
  {
    // console.log("Before " + this.lawyerfeeamountTarget)
    // console.log(this.lawyerfeeamountTarget)
    this.lawyerfeeamountTarget.readOnly = !this.lawyerfeeincludedTarget.checked
    
    if (this.lawyerfeeincludedTarget.checked == false)
    {
      this.lawyerfeeamountTarget.value = 0
    }
    // console.log("After " + this.lawyerfeeamountTarget)
  }

  taxamountchanged()
  {
    // console.log("taxamountchanged")

  }
}
