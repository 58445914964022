import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "sponsor" ]

  connect() {
    //this.element.textContent = "Hello World!"
    console.log("Sponsors Reports")

    $('#sponsor_id').select2();
  }

}
