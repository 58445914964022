import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "client", "contracts", "clientid", "clientpartial", "form", "newclientpartial" ]
  static values = {
    url: String,
    param: String
  }

  connect() {
    console.log("Client connect")
    // console.log(this.urlValue)
    // console.log(this.paramValue)
    // console.log(this.clientidTarget.value)
  }

  new(event) {
    event.preventDefault()
    // console.log("new client")
    // console.log(this.hasFormTarget)
    // console.log(this.hasClientpartialTarget)
    // console.log(this.hasNewclientpartialTarget)

    console.log(this.element)
    this.clientpartialTarget.innerHTML = this.newclientpartialTarget.innerHTML
  }

  enquire(event) {
    event.preventDefault()

    let params = new URLSearchParams()
    params.append(this.paramValue, this.clientidTarget.value)//event.target.selectedOptions[0].value)

    // console.log(this.hasParamValue)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }

}
