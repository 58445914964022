import MazayaNestedForm from "stimulus-rails-nested-form"
import TomSelect      from "tom-select"
import { get } from "@rails/request.js"

export default class extends MazayaNestedForm {

  connect() {
    super.connect()
    // console.log("MaxayaNestedForm: Do what you want here.")
  }

  remove(event) {
    // let confirmDialog = confirm("هل انت متاكد؟")
    // if (confirmDialog == false) {
    //   return
    // }
    super.remove(event)
  }

  add(event) {
    // console.log("MaxayaNestedForm Add: Do what you want here.")
    super.add(event)
  }

}