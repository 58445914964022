import { Controller } from "@hotwired/stimulus"
// import { FetchRequest, get } from "@rails/request.js"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "client", "contract", "contract_details" ]
  static values = {
    urlclient: String,
    paramclient: String,
    urlcontract: String,
    paramcontract: String
  }

  connect() {
    // $('#installment_client_id').select2();
    // $('#contract_select').select2();
    // $('#installment_employee_id').select2();
    
    // $("#installment_client_id").on('select2:select', function () {
    //   //console.log("list item selected");
    //   let event = new Event('change', { bubbles: true }) // fire a native event
    //   this.dispatchEvent(event);
    // });

    // $("#contract_select").on('select2:select', function () {
    //   //console.log("list item selected");
    //   let event = new Event('change', { bubbles: true }) // fire a native event
    //   this.dispatchEvent(event);
    // });

  }

  click(data) {
    let contract_select = document.getElementById("contract_select")
    let select = contract_select.tomselect

    console.log(select.options)

    select.clear()
    select.clearOptions()
    select.sync()

    console.log(select.options)

  }

  async clientchanged(event) {
    event.preventDefault()

    let params = new URLSearchParams()
    params.append(this.paramclientValue, event.target.selectedOptions[0].value)

    const response = await get(`${this.urlclientValue}?${params}`, {
      // responseKind: "turbo-stream"
      responseKind: "json"
    })

    if (response.ok) {
      let contract_select = document.getElementById("contract_select")
      let select = contract_select.tomselect

      select.clear()
      select.clearOptions()
      select.addOptions(await response.json)
    }
    else
      console.log(response)

  }

  contractchanged(event) {
    let params = new URLSearchParams()
    params.append(this.paramcontractValue, event.target.selectedOptions[0].value)

    get(`${this.urlcontractValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }

  costamountchanged()
  {
    this.recalculate_required_amount()
  }

  downpaymentchanged()
  {
    this.recalculate_required_amount()
  }

  totalamountchanged()
  {
    this.firstinstallmentamountTarget.value = this.totalamountTarget.value / this.numberofinstallmentsamountTarget.value
  }

  numberofinstallmentschanged()
  {
    this.firstinstallmentamountTarget.value = this.totalamountTarget.value / this.numberofinstallmentsamountTarget.value
  }

  firstinstallmentchanged()
  {
    //this.numberofinstallmentsamountTarget.value = this.totalamountTarget.value / this.firstinstallmentamountTarget.value
  }

  recalculate_required_amount()
  {
    this.requiredamountTarget.value = this.costamountTarget.value - this.downpaymentamountTarget.value
    this.totalamountTarget.min = this.requiredamountTarget.value
  }

  lawyerfeeincludedchanged()
  {
    // console.log("Before " + this.lawyerfeeamountTarget)
    // console.log(this.lawyerfeeamountTarget)
    this.lawyerfeeamountTarget.readOnly = !this.lawyerfeeincludedTarget.checked
    
    if (this.lawyerfeeincludedTarget.checked == false)
    {
      this.lawyerfeeamountTarget.value = 0
    }
    // console.log("After " + this.lawyerfeeamountTarget)
  }
}
