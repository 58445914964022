import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // console.log("Hello World!!!!")
  }

  click(event) {
    // event.preventDefault()

    let level = this.element.dataset.level
    let grandparent = this.element.dataset.grandparent
    let parent = this.element.dataset.parent

    let collapsed = this.element.getAttribute("aria-collapsed")
    console.log("aria: ", collapsed)

    if (level == 1) {
      let qs = document.querySelectorAll(`[data-grandparent="${ grandparent }"]`)
      qs.forEach(
        q => {
          if (q.dataset.level > level) {
            if (collapsed == "false") {
              q.classList.add('hidden')
            } else {
              q.classList.remove('hidden')
            }
          }
        }
      )
    }
    
    if (level == 2) {
      let qs2 = document.querySelectorAll(`[data-grandparent="${ grandparent }"][data-parent="${ parent }"]`)
      qs2.forEach(
        q2 => {
          if (q2.dataset.level > level) {
            if (q2.classList.contains('hidden')) {
              q2.classList.remove('hidden')
            } else {
              q2.classList.add('hidden')
            }
          }
        }
      )
    }
    
    if (collapsed == "false") {
      this.element.setAttribute("aria-collapsed", "true")
    } else {
      this.element.setAttribute("aria-collapsed", "false")
    }

    // if (event.currentTarget.innerHTML == "-") {
    //   event.currentTarget.innerHTML = "+"
    // } else {
    //   event.currentTarget.innerHTML = "-"
    // }

    // console.log("Clicked grandparent: ", grandparent)
    // console.log("Clicked parent: ", parent)
  }
}
