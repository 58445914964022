import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

export default class extends Controller {
  static targets = [ "client", "select" ]

  config() {
    var config = {
      plugins: ['dropdown_input'],
      create: false,
      maxItems: 1,
      maxOptions: null,
      allowEmptyOption: true,
      hidePlaceholder: true,
      // openOnFocus: false
    }

    return config
  }

  connect() {
    // console.log("Clients Reports")
    // console.log(this.hasSelectTarget)
    
    // this.selectTargets.forEach(element => {
    //   new TomSelect(element, this.config())
    // });

    // $('#client_id').select2();
    // $('#sponsor_id').select2();
    // $('#investor_id').select2();
  }

  // submit_clicked(e) {
  //   document.getElementById("submit_button_icon").classList.add("animate-bounce")
  // }

  // submitEnd(e) {
  //   document.getElementById("submit_button_icon").classList.remove("animate-bounce")
  // }

  // filter_clicked(e) {
  //   e.preventDefault()
  //   document.getElementById("filter_fields").classList.toggle("-translate-y-full")
  //   document.getElementById("filter_fields").classList.toggle("translate-y-0")
    
  //   document.getElementById("results").classList.toggle("-translate-y-20")
  //   document.getElementById("results").classList.toggle("translate-y-0")
  //   // document.getElementById("page_top").classList.toggle("pt-0")
  // }

}