import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "client", "contracts" ]
  static values = {
    url: String,
    param: String
  }

  connect() {
    // $('#client_id').select2();

    // $("#client_id").on('select2:select', function () {
    //   //console.log("list item selected");
    //   let event = new Event('change', { bubbles: true }) // fire a native event
    //   this.dispatchEvent(event);
    // });
  }

  clientchanged(event) {
    let params = new URLSearchParams()
    params.append(this.paramValue, event.target.selectedOptions[0].value)

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
