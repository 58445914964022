import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="side-menu"
export default class extends Controller {
  static targets = [ "headerbutton", "button", "up", "down" ]

  connect() {
    // console.log("side menu")
    this.checkCookie(this.headerbuttonTarget.dataset.cname, true)
    // console.log(this.headerbuttonTarget.dataset.cname)
  }

  click(event) {
    event.preventDefault()

    this.upTarget.classList.toggle("hidden")
    this.downTarget.classList.toggle("hidden")

    this.buttonTargets.forEach(
      function(button) {
        button.classList.toggle("hidden")
      }
    )

    // console.log(this.upTarget.classList.contains("hidden"))
    this.setCookie(this.headerbuttonTarget.dataset.cname, !this.upTarget.classList.contains("hidden"), 365)

    // this.remove_highlights()
    // this.buttonTarget.classList.add("bg-yellow-100")
  }

  remove_highlights() {
    let nodes = document.getElementsByClassName('title')
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].classList.remove('bg-yellow-100')
    }
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  
  getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  checkCookie(cname, cvalue) {
    let user = this.getCookie(cname);
    if (user != "") {
      return user
    } else {
      user = cvalue
      this.setCookie(cname, user, 365);
    }
  }
}
