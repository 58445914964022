import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "quantity", "salesamount", "totalsales", "item" ]

  connect() {
    // console.log("ContractItem Connect")
    this.calculate_item_total()
  }

  itemchanged() {
    // console.log("ContractItem Item changed")
    this.calculate_item_total()
  }
  
  quantitychanged() {
    // console.log("ContractItem Quantity changed")
    // console.log(this.hasitemTarget)
    this.calculate_item_total()
  }

  salesamountchanged() {
    // console.log("ContractItem Sales Amount changed")
    // console.log(this.hasquantityTarget)
    this.calculate_item_total()
  }

  calculate_item_total() {
    // console.log("Calculate Item Total changed")
    // console.log(this.totalsalesTarget.innerHTML)
    this.totalsalesTarget.innerHTML = (parseFloat(this.quantityTarget.value) * parseFloat(this.salesamountTarget.value)).toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3})
  }
}
