import { Controller } from "@hotwired/stimulus"
// import TomSelect      from "tom-select"
export default class extends Controller {
  static targets = [ "quantity", "salesamount", "totalsales", "taxpercentage", "pretaxtotal", "taxamount", "totalamount", "item" ]

  // config() {
  //   var config = {
  //     plugins: ['dropdown_input'],
  //     create: false,
  //     maxItems: 1,
  //     maxOptions: null,
  //     allowEmptyOption: true,
  //     hidePlaceholder: true,
  //     // openOnFocus: false
  //   }

  //   return config
  // }

  connect() {
    //this.element.textContent = "Hello World!"
    // console.log("Invoices")
    // console.log("Do what you want here.")

    // new TomSelect($('#invoice_client_id'), this.config())
    // new TomSelect($('#invoice_payment_type_id'), this.config())
    // new TomSelect($('#invoice_branch_id'), this.config())
    // $('#invoice_client_id').select2();
    // $('#invoice_payment_type_id').select2();
    // $('#invoice_branch_id').select2();

    this.calculate_item_total()
  }

  quantitychanged() {
    // console.log("Quantity changed")
    this.calculate_item_total()
  }

  salesamountchanged() {
    // console.log("Cost Price changed")
    this.calculate_item_total()
  }

  calculate_item_total() {
    if (this.hasTaxamountTarget) {
      // console.log("taxpercentage: " + this.taxpercentageTarget.value)
      if ((this.pretaxtotalTarget.value))
      {
        // console.log("pretaxtotal: " + this.pretaxtotalTarget.value)
        // console.log("taxamount: " + this.taxamountTarget.value)
        this.taxamountTarget.value = parseFloat(this.pretaxtotalTarget.value) * parseFloat(this.taxpercentageTarget.value) / 100
        this.totalamountTarget.value = parseFloat(this.pretaxtotalTarget.value) + parseFloat(this.taxamountTarget.value)
      }
    }
    else {
      if ((this.pretaxtotalTarget.value))
      {
        this.totalamountTarget.value = parseFloat(this.pretaxtotalTarget.value)
      }
    }
    // this.totalsalesTarget.innerHTML = (parseFloat(this.quantityTarget.value) * parseFloat(this.salesamountTarget.value)).toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3})
  }

}
