import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comments"
export default class extends Controller {
  static targets = [ "commentedit" ]
  static values = {
    currentuser: String,
    senderuser: String,
  }

  connect() {
    // console.log("comment connected!")
    // console.log(this.currentuserValue)
    // console.log(this.senderuserValue)
    let xx = document.getElementById("comments_current_user")
    // console.log(xx.innerHTML)

    if (xx.innerHTML != this.senderuserValue){
      this.commenteditTarget.classList.add("hidden")
    }
  }

}
