import ContractNestedForm from "stimulus-rails-nested-form"
import TomSelect      from "tom-select"

export default class extends ContractNestedForm {
  static targets = [ "quantity", "salesamount", "totalsales", "item" ]

  config() {
    var config = {
      plugins: ['dropdown_input'],
      create: false,
      maxItems: 1,
      maxOptions: null,
      allowEmptyOption: true,
      hidePlaceholder: true,
      // openOnFocus: false
    }

    return config
  }

  connect() {
    super.connect()
    // console.log("ContractNestedForm: Do what you want here.")

    this.itemTargets.forEach(element => {
      new TomSelect(element, this.config())
    });

    this.update_total_values()

    // $('#client_id').select2();
    // $('#sponsor_id').select2();
  }

  remove(event) {
    let confirmDialog = confirm("هل انت متاكد؟")
    if (confirmDialog == false) {
      return
    }
    super.remove(event)
    this.update_total_values()
  }

  add(event) {
    super.add(event)
    new TomSelect(this.itemTargets[this.itemTargets.length - 1], this.config())
    this.update_total_values()
  }

  itemchanged() {
    // console.log("ContractNestedForm Item changed")
    this.update_total_values()
  }

  quantitychanged() {
    // console.log("ContractNestedForm Quantity changed")
    this.update_total_values()
  }

  salesamountchanged() {
    // console.log("ContractNestedForm Cost Price changed")
    this.update_total_values()
  }

  update_total_values() {
    var prices = document.querySelectorAll("[id^=sales_amount_]")
    var quantities = document.querySelectorAll("[id^=quantity_]")
    var deleted = document.querySelectorAll("[id^=the_hidden_field_]")
    // var items = document.querySelectorAll("[id^=item_]")

    var total_quantities = 0
    var totals = 0
    // var product_sold_description = ""

    for (var i = 0, len = prices.length; i < len; i++) {
      if (deleted[i].value != '1')
      {
        // product_sold_description += " و " + items[i].options[items[i].selectedIndex].text
        total_quantities += parseFloat(quantities[i].value)
        totals += parseFloat(prices[i].value) * parseFloat(quantities[i].value)
      }
    }
    
    document.getElementById("total_sales").innerHTML = totals.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3})
    document.getElementById("total_quantity").innerHTML = total_quantities
    // document.getElementById("product_sold").innerHTML = product_sold_description
    document.getElementById("contract_pretax_total").value = totals
  }
}