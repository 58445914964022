import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

export default class extends Controller {
  static targets = [ "select" ]

  config() {
    var config = {
      plugins: ['dropdown_input'],
      create: false,
      maxItems: 1,
      maxOptions: null,
      allowEmptyOption: true,
      hidePlaceholder: true,
      // openOnFocus: false
    }

    return config
  }

  connect() {
    // console.log("tom select!!")

    console.log(this.hasSelectTarget)
    console.log(this.selectTargets.length)

    this.selectTargets.forEach(element => {
      new TomSelect(element, this.config)
    });
  }

}