import InvoiceNestedForm from "stimulus-rails-nested-form"
import TomSelect      from "tom-select"

export default class extends InvoiceNestedForm {
  static targets = [ "item", "quantity", "salesamount", "totalsales" ]

  config() {
    var config = {
      plugins: ['dropdown_input'],
      create: false,
      maxItems: 1,
      maxOptions: null,
      allowEmptyOption: true,
      hidePlaceholder: true,
      // openOnFocus: false
    }

    return config
  }

  connect() {
    super.connect()
    // console.log("InvoiceNestedForm: Do what you want here.")
    
    this.itemTargets.forEach(element => {
      new TomSelect(element, this.config())
    });

    this.update_total_values()
  }

  remove(event) {
    let confirmDialog = confirm("Are you sure?")
    if (confirmDialog == false) {
      return
    }
    super.remove(event)
    this.update_total_values()
  }

  add(event) {
    super.add(event)
    new TomSelect(this.itemTargets[this.itemTargets.length - 1], this.config())
    this.update_total_values()
  }

  quantitychanged() {
    // console.log("PT Quantity changed")
    this.update_total_values()
  }

  salesamountchanged() {
    // console.log("PT Cost Price changed")
    this.update_total_values()
  }

  update_total_values() {
    var prices = document.querySelectorAll("[id^=sales_amount_]")
    var quantities = document.querySelectorAll("[id^=quantity_]")
    var deleted = document.querySelectorAll("[id^=the_hidden_field_]")

    var total_quantities = 0
    var totals = 0

    for (var i = 0, len = prices.length; i < len; i++) {
      if (deleted[i].value != '1')
      {
        total_quantities += parseFloat(quantities[i].value)
        totals += parseFloat(prices[i].value) * parseFloat(quantities[i].value)
      }
    }

    // for (var i = 0, len = quantities.length; i < len; i++) {
    //   total_quantities += parseFloat(quantities[i].value)
    // }

    // console.log("total_prices = " + total_prices)
    // console.log("total_quantities = " + total_quantities)
    // console.log("prices = " + prices.length)
    // console.log("quantities = " + quantities.length)
    // console.log("totals = " + totals)
    document.getElementById("total_sales").innerHTML = totals.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3})
    document.getElementById("total_quantity").innerHTML = total_quantities
    document.getElementById("invoice_pretax_total").value = totals
  }
}