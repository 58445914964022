import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="report"
export default class extends Controller {
  connect() {
  }

  submit_clicked(e) {
    document.getElementById("submit_button_icon").classList.add("animate-bounce")
  }

  submitEnd(e) {
    document.getElementById("submit_button_icon").classList.remove("animate-bounce")
  }

  filter_clicked(e) {
    e.preventDefault()
    document.getElementById("filter_fields").classList.toggle("-translate-y-full")
    document.getElementById("filter_fields").classList.toggle("translate-y-0")
    
    document.getElementById("results").classList.toggle("-translate-y-20")
    document.getElementById("results").classList.toggle("translate-y-0")
    // document.getElementById("page_top").classList.toggle("pt-0")
  }
}
