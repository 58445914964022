import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="deletecontract"
export default class extends Controller {
  static targets = [ "confirmationpartial", "newconfirmationpartial" ]
  connect() {
  }

  delete(event) {
    event.preventDefault()

    // console.log(event.currentTarget.id)
    // console.log(this.newconfirmationpartialTarget.id)
    // console.log(this.newconfirmationpartialTargets)
    // console.log(event.currentTarget.closest("#" + event.currentTarget.id.replace('button_','')))
    // console.log(event.currentTarget.id.replace('button_',''))
    // console.log(document.getElementById(event.currentTarget.id.replace('button_','')))
    // this.confirmationpartialTarget.innerHTML = this.newconfirmationpartialTarget.innerHTML
    this.confirmationpartialTarget.innerHTML = document.getElementById(event.currentTarget.id.replace('button_','')).innerHTML
  }
}
