import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "template", "target", "select", "client", "contract", "employee", "paymenttype" ]

  static values = {
    urlclient: String,
    paramclient: String,
    urlcontract: String,
    paramcontract: String
  }

  config() {
    var config = {
      plugins: ['dropdown_input'],
      create: false,
      maxItems: 1,
      maxOptions: null,
      allowEmptyOption: true,
      hidePlaceholder: true,
      // openOnFocus: false
    }

    return config
  }

  connect() {
    super.connect()
    // console.log("ImportedInstallments: Do what you want here.")
    this.tsClient
    this.tsContract
    this.tsPaymentType

    if (this.hasEmployeeTarget) {
      this.tsEmployee
    }

    // console.log(this.clientTargets.length)
}

  remove(event) {
    // let confirmDialog = confirm("هل انت متاكد؟")
    // if (confirmDialog == false) {
    //   return
    // }
    super.remove(event)
  }

  add(event) {
    // console.log("ImportedInstallments Add: Do what you want here.")
    super.add(event)

    this.tsClient
    this.tsContract
    this.tsPaymentType

    // console.log("Employee Target: " + this.hasEmployeeTarget)
    if (this.hasEmployeeTarget) {
      this.tsEmployee
    }

    // console.log(this.clientTargets.length)
  }

  async clientchanged(event) {
    // console.log(this.hasUrlclientValue)
    // console.log(this.hasParamclientValue)
    // console.log(this.hasTemplateTarget)

    let params = new URLSearchParams()
    params.append(this.paramclientValue, event.target.selectedOptions[0].value)
    
    // console.log(`${this.urlclientValue}?${params}`)

    get(`${this.urlclientValue}?${params}`, {
      // responseKind: "turbo-stream"
      responseKind: "json"
    })

    if (response.ok) {
      this.clearItems()
      this.tomSelect.addOptions(await response)
    }
    else
      console.log(response)
    
  }

  async fetchContracts() {
    let params = new URLSearchParams()
    params.append(this.paramclientValue, event.target.selectedOptions[0].value)

    const response = await get(`${this.urlclientValue}?${params}`, {
      responseKind: 'json'
    })

    if (response.ok)
      this.setContracts(await response.json)
    else
      console.log(response)
  }

  setContracts(contracts) {
    this.clearContracts()
    this.tsContract.addOptions(contracts)
  }

  clearContracts() {
    this.tsContract.clear()
    this.tsContract.clearOptions()
  }

  get selectedFilter() {
    return this.tsContract.value
  }

  get tsClient() {
    this._tsClient ||= new TomSelect(this.clientTarget, this.config())
    return this._tsClient
  }

  get tsContract() {
    this._tsContract ||= new TomSelect(this.contractTarget, this.config())
    return this._tsContract
  }

  get tsEmployee() {
    this._tsEmployee ||= new TomSelect(this.employeeTarget, this.config())
    return this._tsEmployee
  }

  get tsPaymentType() {
    this._tsPaymentType ||= new TomSelect(this.paymenttypeTarget, this.config())
    return this._tsPaymentType
  }
}