import { StreamActions } from "@hotwired/turbo"
import TomSelect      from "tom-select"

StreamActions.serial_search = function() {
  const item_id = this.getAttribute("item_id")
  const price = this.getAttribute("price")
  const serial = this.getAttribute("serial")

  // console.log(`ITEM: ${item_id} - price: ${price} - serial: ${serial}`)

  // Check to see if the item_id is already in the list
  // if NOT add it to the list
  //    then add the serial number under the item
  //    update error to success and tell the user the item has been added
  // if in the list, increase the number by 1
  //    check if the serial is in the list below the item
  //      if it is, show error message that serial already added
  //      if it is NOT then add the serial number under the item
  //        update error to success and tell the user the item has been added
  // recalculate totals

  if (is_item_in_list(item_id) == true) {
    if (is_serial_in_list(item_id, serial)) {
      // display_message("Item Already Added")
    } else {
      update_item_quantity(item_id)
      add_serial(item_id, serial)
      // display_message("Item Quantity Updated")
    }
  } else {
    add_new_item(item_id, price)
    add_serial(item_id, serial)
    // tom_select(item_id)
    // display_message("Item Added")
  }

  clear_search()
  update_total_values()
  update_total_amount()
}

function display_message(message) {
  console.log(message)
}

function clear_search() {
  searchElement = document.getElementById("serial_search")
  if (searchElement) {
    searchElement.value = ""
  }
}

function is_serial_in_list(item_id, serial) {
  var result = false

  const add_item_fields = document.querySelector(`div[name="items_fields_${item_id}"]`)
  const serials = add_item_fields.querySelectorAll("#serial_field")
  for (var i = 0, len = serials.length; i < len; i++) {
    if (serials[i].value.toString() == serial.toString()) {
      result = true
      break
    }
  }

  return result
}

function is_item_in_list(item_id) {
  var result = false

  var items = document.querySelectorAll("[id^=item_]")
  for (var i = 0, len = items.length; i < len; i++) {
    if (items[i].value.toString() == item_id.toString()) {
      result = true
      break
    }
  }

  return result
}

function add_new_item(item_id, price) {
  const item_template = document.getElementById("items_template")
  const item_fields = item_template.content.querySelector("#items_fields")
  item_fields.setAttribute("name", `items_fields_${item_id}`)
  
  // Find the select element in the item_template
  const selectElement = item_template.content.querySelector('select[name="open_contract[contract_items_attributes][NEW_RECORD][item_id]"]')
  if (selectElement) {
    // Set the desired option (value="item_id") as selected
    const optionToSelect = selectElement.querySelector(`option[value="${item_id}"]`)
    if (optionToSelect) {
      // Add the "selected" attribute to the desired option
      optionToSelect.setAttribute("selected", "selected")
    }
  }
  
  const priceElement = item_template.content.querySelector('input[name="open_contract[contract_items_attributes][NEW_RECORD][sales_amount]"]')
  if (priceElement) {
    priceElement.setAttribute("value", price)
  }

  const quantityElement = item_template.content.querySelector('input[name="open_contract[contract_items_attributes][NEW_RECORD][quantity]"]')
  if (quantityElement) {
    quantityElement.setAttribute("value", 1)
  }

  const templateContent = item_template.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())

  const it = document.getElementById("items_template_target")
  it.insertAdjacentHTML('beforebegin', templateContent)
  item_fields.setAttribute("name", `items_fields`)

  selectElement.options[selectElement.selectedIndex].remove()
  priceElement.setAttribute("value", 0)
}

function update_item_quantity(item_id) {
  const add_item_fields = document.querySelector(`div[name="items_fields_${item_id}"]`)
  const quantityElement = add_item_fields.querySelector("#quantity_new_contract_item")
  if (quantityElement) {
    currentQuantity = Number(quantityElement.value) + 1
    quantityElement.setAttribute("value", currentQuantity)
    
    const salesElement = add_item_fields.querySelector("#sales_amount_new_contract_item")
    const totalElement = add_item_fields.querySelector("#total_item_sales")
    if (totalElement) {
      totalElement.innerHTML = (parseFloat(quantityElement.value) * parseFloat(salesElement.value)).toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3})
    }
  }
}

function add_serial(item_id, serial) {
  const add_item_fields = document.querySelector(`div[name="items_fields_${item_id}"]`)  
  const serial_template = add_item_fields.querySelector("#serials_template")

  // Find the select element in the template
  const serialElement = serial_template.content.querySelector('#serial_field')
  if (serialElement) {
    serialElement.setAttribute("value", serial)
  }
  
  const serialContent = serial_template.innerHTML.replace(/NEW_SERIAL/g, new Date().getTime().toString())

  // const st = document.getElementById("serials_template_target")
  const st = add_item_fields.querySelector("#serials_template_target")
  st.insertAdjacentHTML('beforebegin', serialContent)
}

function update_total_values() {
  var prices = document.querySelectorAll("[id^=sales_amount_]")
  var quantities = document.querySelectorAll("[id^=quantity_]")

  var total_quantities = 0
  var totals = 0

  for (var i = 0, len = prices.length; i < len; i++) {
    total_quantities += parseFloat(quantities[i].value)
    totals += parseFloat(prices[i].value) * parseFloat(quantities[i].value)
  }
  
  document.getElementById("total_sales").innerHTML = totals.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3})
  document.getElementById("total_quantity").innerHTML = total_quantities
  document.getElementById("contract_pretax_total").value = totals
}

function update_total_amount() {
  // this.taxamountTarget = contract_tax_amount
  // this.pretaxtotalTarget = contract_pretax_total
  // this.downpaymentamountTarget = contract_down_payment
  // this.numberofinstallmentsamountTarget = contract_number_of_installments
  // this.taxpercentageTarget = contract_tax_percentage
  // this.totalamountTarget = contract_total_amount
  // this.firstinstallmentamountTarget = contract_first_installment_amount
  // this.aftertaxtotalTarget = contract_after_tax_total

  taxAmount = document.getElementById("contract_tax_amount")
  preTaxTotal = document.getElementById("contract_pretax_total")
  downPaymentAmount = document.getElementById("contract_down_payment")
  numberOfInstallments = document.getElementById("contract_number_of_installments")
  taxPercentage = document.getElementById("contract_tax_percentage")
  totalAmount = document.getElementById("contract_total_amount")
  firstInstallmentAmount = document.getElementById("contract_first_installment_amount")
  afterTaxTotal = document.getElementById("contract_after_tax_total")

  if (contract_tax_amount) {
    if ((preTaxTotal.value) && (downPaymentAmount.value) && (numberOfInstallments.value))
    {
      contract_tax_amount.value = parseFloat(preTaxTotal.value) * parseFloat(taxPercentage.value) / 100
      totalAmount.value = parseFloat(preTaxTotal.value) + parseFloat(contract_tax_amount.value) - parseFloat(downPaymentAmount.value)
      contract_first_installment_amount.value = calculate_first_installment(totalAmount.value, numberOfInstallments.value)
      afterTaxTotal.innerHTML = parseFloat(contract_tax_amount.value) + parseFloat(preTaxTotal.value)
    }
  }
  else {
    if ((preTaxTotal.value) && (downPaymentAmount.value) && (numberOfInstallments.value))
    {
      totalAmount.value = parseFloat(preTaxTotal.value) - parseFloat(downPaymentAmount.value)
      contract_first_installment_amount.value = calculate_first_installment(totalAmount.value, numberOfInstallments.value)
    }
  }
}

function calculate_first_installment(amount, installments)
{
  if (installments > 0) {
    return parseInt(amount/installments)
  } else {
    return 0
  }
}

function tom_select(item_id) {
  const add_item_fields = document.querySelector(`div[name="items_fields_${item_id}"]`)
  const item_select = add_item_fields.querySelector("#item_new_contract_item")
  new TomSelect(item_select, config())
}

function config() {
  var config = {
    plugins: ['dropdown_input'],
    create: false,
    maxItems: 1,
    maxOptions: null,
    allowEmptyOption: true,
    hidePlaceholder: true,
    // openOnFocus: false
  }

  return config
}

// ********************************************************************
{/* <template data-contract-nested-form-target="template" id="item_template">

<div class="contract-nested-form-wrapper col-span-6" data-controller="contract-item" data-new-record="true">

<div class="flex space-x-4">
  <div class="col-span-6 row-span-2 sm:col-span-3 w-3/6">
    <label class="system_label" for="open_contract_contract_items_attributes_NEW_RECORD_item_id">المنتج</label>
    <select data-contract-target="item" data-contract-nested-form-target="item" data-contract-item-target="item" data-action="contract-nested-form#itemchanged contract#itemchanged contract-item#itemchanged" type="select" id="item_new_contract_item" class="ts_system_select_field" name="open_contract[contract_items_attributes][NEW_RECORD][item_id]"><option value="">اختر المنتج</option>
<option value="3">أيفون 13برو ماكس 256 جيجا أزرق</option>
<option value="4">أيفون 12برو ماكس 256 جيجا أزرق</option>
<option value="5">أيفون 13برو ماكس 256 جيجا فضي</option>
<option value="6">أيفون 13برو ماكس 256 جيجا ذهبي</option>
<option value="7">أيفون 13برو  256 جيجا أزرق</option>
<option value="8"> أيفون 13برو ماكس 256 جيجا اسود</option>
<option value="9">أيفون 13برو ماكس 256 جيجا أخضر صنوبري</option>
<option value="10">أيفون 14برو ماكس 256 جيجا ذهبي </option>
<option value="11">أيفون 14 برو ماكس 256 جيجا فضي</option>
<option value="12">أيفون 14برو ماكس 256 جيجا اسود فلكي</option>
<option value="13"> أيفون 14برو ماكس 256 جيجا بنفسجي عميق</option></select>
  </div>

  <div class="col-span-6 sm:col-span-1 w-1/6">
    <label class="system_label" for="open_contract_contract_items_attributes_NEW_RECORD_sales_amount">سعر البيع</label>
    <input value="0.0" data-contract-target="salesamount" data-contract-nested-form-target="salesamount" data-contract-item-target="salesamount" data-action="contract-nested-form#salesamountchanged contract#salesamountchanged contract-item#salesamountchanged" id="sales_amount_new_contract_item" class="system_number_field" type="number" name="open_contract[contract_items_attributes][NEW_RECORD][sales_amount]">
  </div>

  <div class="col-span-6 sm:col-span-1 w-1/6">
    <label class="system_label" for="open_contract_contract_items_attributes_NEW_RECORD_quantity">الكمية</label>
    <input value="0" data-contract-target="quantity" data-contract-nested-form-target="quantity" data-contract-item-target="quantity" data-action="contract-nested-form#quantitychanged contract#quantitychanged contract-item#quantitychanged" id="quantity_new_contract_item" class="system_number_field" type="number" name="open_contract[contract_items_attributes][NEW_RECORD][quantity]">
  </div>

  <div class="col-span-6 sm:col-span-1 w-1/6">
    <div class="col-span-1 sm:col-span-1">
      <label class="system_label">الاجمالي</label>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <label type="text" ,="" data-contract-target="totalsales" data-contract-nested-form-target="totalsales" data-contract-item-target="totalsales" class="system_show_field">0.000</label>
    </div>
  </div>

  <button type="button" data-action="contract-nested-form#remove" class="mt-9 inline-flex items-center px-2 h-6 border border-transparent rounded-md shadow-sm text-sm font-small text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
    x
  </button>

  <input id="the_hidden_field_new_contract_item" autocomplete="off" type="hidden" value="false" name="open_contract[contract_items_attributes][NEW_RECORD][_destroy]">
</div>

<div data-controller="contract-serial-nested-form" data-contract-serial-nested-form-wrapper-selector-value=".contract-serial-nested-form-wrapper">
  <template data-contract-serial-nested-form-target="template" id="serial_template">

  <div class="contract-serial-nested-form-wrapper col-span-6 flex space-x-2" data-new-record="true">
  <div class="col-span-6 sm:col-span-3">
    <input class="mt-2 bg-blue-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" type="text" name="open_contract[contract_items_attributes][NEW_RECORD][serials_attributes][NEW_SERIAL][serial]" id="open_contract_contract_items_attributes_NEW_RECORD_serials_attributes_NEW_SERIAL_serial">
  </div>

  <button type="button" data-action="contract-serial-nested-form#remove" class="mt-5 inline-flex items-center h-6 px-2 border border-transparent rounded-md shadow-sm text-sm font-small text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
    x
  </button>

  <input id="serial_delete_id_new_serial" autocomplete="off" type="hidden" value="false" name="open_contract[contract_items_attributes][NEW_RECORD][serials_attributes][NEW_SERIAL][_destroy]">
</div>
</template>

<!-- Inserted elements will be injected before that target. -->
<div data-contract-serial-nested-form-target="target" id="serial_target"></div>

</div>

</div>

</template> */}
// ********************************************************************
{/* <template data-contract-serial-nested-form-target="template" id="serials_template" name="serials_template">
  
    <div class="contract-serial-nested-form-wrapper col-span-6 flex space-x-2" data-new-record="true">
    <div class="col-span-6 sm:col-span-3">
      <input class="mt-2 bg-blue-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" type="text" name="open_contract[contract_items_attributes][NEW_RECORD][serials_attributes][NEW_SERIAL][serial]" id="open_contract_contract_items_attributes_NEW_RECORD_serials_attributes_NEW_SERIAL_serial">
    </div>

    <button type="button" data-action="contract-serial-nested-form#remove" class="mt-5 inline-flex items-center h-6 px-2 border border-transparent rounded-md shadow-sm text-sm font-small text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
      x
    </button>

    <input id="serial_delete_id_new_serial" autocomplete="off" type="hidden" value="false" name="open_contract[contract_items_attributes][NEW_RECORD][serials_attributes][NEW_SERIAL][_destroy]">
</div>
</template> */}
// ********************************************************************
