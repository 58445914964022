import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "investor" ]

  connect() {
    //this.element.textContent = "Hello World!"
    console.log("Investors Reports")

    $('#investor_id').select2();
  }

}
