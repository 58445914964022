import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // console.log("Hello World!")
  }

  keypressed(event) {
    // console.log(event)
    // console.log(event.target.value)
    // console.log(event.target.value.toString().split(".")[0].length)
    
    if (this.countDecimals(event.target.value) == false) {
      event.preventDefault()
      return false
    }
  }

  countDecimals(value) {
    // console.log("value: " + value.toString())

    if(value.toString() === "") return true;
    if(Math.floor(value) === value) return true;
    // if(value.toString().split(".").length > 1) return false;
    if(value.toString().split(".").length == 2) {
      if (value.toString().split(".")[1].length >= 2) {
        // console.log("ok")
        return false;
      }
    }
  }

}
