import InvoiceSerialNestedForm from "stimulus-rails-nested-form"

export default class extends InvoiceSerialNestedForm {

  connect() {
    super.connect()
    // console.log("InvoiceSerialNestedForm: Do what you want here.")
  }

  add (e) {
    e.preventDefault()
    // console.log("Add")
    // const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    // this.targetTarget.insertAdjacentHTML('beforebegin', content)

    const content = this.templateTarget.innerHTML.replace(/NEW_SERIAL/g, new Date().getTime().toString())
    this.targetTarget.insertAdjacentHTML('beforebegin', content)

    // const content = e.target.parentElement.querySelector("#serial_template").innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    // e.target.parentElement.querySelector("#serial_target").insertAdjacentHTML('beforebegin', content)

    // document.querySelectorAll("[id^=add_serial]")[1].parentElement.querySelector("#serial_template").innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    // document.getElementById(target_id).insertAdjacentHTML('beforebegin', content)
    // e.target.closest("#serial_target").insertAdjacentHTML('beforebegin', content)
  }
}
