import { StreamActions } from "@hotwired/turbo"

StreamActions.update_total_values = function() {
  var prices = document.querySelectorAll("[id^=sales_amount_]")
  var quantities = document.querySelectorAll("[id^=quantity_]")
  var deleted = document.querySelectorAll("[id^=the_hidden_field_]")

  var total_quantities = 0
  var totals = 0

  for (var i = 0, len = prices.length; i < len; i++) {
    if (deleted[i].value != '1')
    {
      total_quantities += parseFloat(quantities[i].value)
      totals += parseFloat(prices[i].value) * parseFloat(quantities[i].value)
    }
  }
  
  document.getElementById("total_sales").innerHTML = totals.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3})
  document.getElementById("total_quantity").innerHTML = total_quantities
  document.getElementById("contract_pretax_total").value = totals

  // if (this.hasTaxamountTarget) {
  //   if ((this.pretaxtotalTarget.value) && (this.downpaymentamountTarget.value) && (this.numberofinstallmentsamountTarget.value))
  //   {
  //     // console.log("taxamount: " + this.taxamountTarget.value)
  //     this.taxamountTarget.value = parseFloat(this.pretaxtotalTarget.value) * parseFloat(this.taxpercentageTarget.value) / 100
  //     this.totalamountTarget.value = parseFloat(this.pretaxtotalTarget.value) + parseFloat(this.taxamountTarget.value) - parseFloat(this.downpaymentamountTarget.value)
  //     this.firstinstallmentamountTarget.value = this.calculatefirstinstallment(this.totalamountTarget.value, this.numberofinstallmentsamountTarget.value)
  //     this.aftertaxtotalTarget.innerHTML = parseFloat(this.taxamountTarget.value) + parseFloat(this.pretaxtotalTarget.value)
  //   }
  // }
  // else {
  //   if ((this.pretaxtotalTarget.value) && (this.downpaymentamountTarget.value) && (this.numberofinstallmentsamountTarget.value))
  //   {
  //     this.totalamountTarget.value = parseFloat(this.pretaxtotalTarget.value) - parseFloat(this.downpaymentamountTarget.value)
  //     this.firstinstallmentamountTarget.value = this.calculatefirstinstallment(this.totalamountTarget.value, this.numberofinstallmentsamountTarget.value)
  //   }
  // }
}

{/* <template data-contract-nested-form-target="template" id="item_template">
  
  <div class="contract-nested-form-wrapper col-span-6" data-controller="contract-item" data-new-record="true">

<div class="flex space-x-4">
  <div class="col-span-6 row-span-2 sm:col-span-3 w-3/6">
    <label class="system_label" for="open_contract_contract_items_attributes_NEW_RECORD_item_id">المنتج</label>
    <select data-contract-target="item" data-contract-nested-form-target="item" data-contract-item-target="item" data-action="contract-nested-form#itemchanged contract#itemchanged contract-item#itemchanged" type="select" id="item_new_contract_item" class="ts_system_select_field" name="open_contract[contract_items_attributes][NEW_RECORD][item_id]"><option value="">اختر المنتج</option>
<option value="3">أيفون 13برو ماكس 256 جيجا أزرق</option>
<option value="4">أيفون 12برو ماكس 256 جيجا أزرق</option>
<option value="5">أيفون 13برو ماكس 256 جيجا فضي</option>
<option value="6">أيفون 13برو ماكس 256 جيجا ذهبي</option>
<option value="7">أيفون 13برو  256 جيجا أزرق</option>
<option value="8"> أيفون 13برو ماكس 256 جيجا اسود</option>
<option value="9">أيفون 13برو ماكس 256 جيجا أخضر صنوبري</option>
<option value="10">أيفون 14برو ماكس 256 جيجا ذهبي </option>
<option value="11">أيفون 14 برو ماكس 256 جيجا فضي</option>
<option value="12">أيفون 14برو ماكس 256 جيجا اسود فلكي</option>
<option value="13"> أيفون 14برو ماكس 256 جيجا بنفسجي عميق</option></select>
  </div>

  <div class="col-span-6 sm:col-span-1 w-1/6">
    <label class="system_label" for="open_contract_contract_items_attributes_NEW_RECORD_sales_amount">سعر البيع</label>
    <input value="0.0" data-contract-target="salesamount" data-contract-nested-form-target="salesamount" data-contract-item-target="salesamount" data-action="contract-nested-form#salesamountchanged contract#salesamountchanged contract-item#salesamountchanged" id="sales_amount_new_contract_item" class="system_number_field" type="number" name="open_contract[contract_items_attributes][NEW_RECORD][sales_amount]">
  </div>

  <div class="col-span-6 sm:col-span-1 w-1/6">
    <label class="system_label" for="open_contract_contract_items_attributes_NEW_RECORD_quantity">الكمية</label>
    <input value="0" data-contract-target="quantity" data-contract-nested-form-target="quantity" data-contract-item-target="quantity" data-action="contract-nested-form#quantitychanged contract#quantitychanged contract-item#quantitychanged" id="quantity_new_contract_item" class="system_number_field" type="number" name="open_contract[contract_items_attributes][NEW_RECORD][quantity]">
  </div>

  <div class="col-span-6 sm:col-span-1 w-1/6">
    <div class="col-span-1 sm:col-span-1">
      <label class="system_label">الاجمالي</label>
    </div>
    <div class="col-span-1 sm:col-span-1">
      <label type="text" ,="" data-contract-target="totalsales" data-contract-nested-form-target="totalsales" data-contract-item-target="totalsales" class="system_show_field">0.000</label>
    </div>
  </div>

  <button type="button" data-action="contract-nested-form#remove" class="mt-9 inline-flex items-center px-2 h-6 border border-transparent rounded-md shadow-sm text-sm font-small text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
    x
  </button>

  <input id="the_hidden_field_new_contract_item" autocomplete="off" type="hidden" value="false" name="open_contract[contract_items_attributes][NEW_RECORD][_destroy]">
</div>

<div data-controller="contract-serial-nested-form" data-contract-serial-nested-form-wrapper-selector-value=".contract-serial-nested-form-wrapper">
  <template data-contract-serial-nested-form-target="template" id="serial_template">

  <div class="contract-serial-nested-form-wrapper col-span-6 flex space-x-2" data-new-record="true">
  <div class="col-span-6 sm:col-span-3">
    <input class="mt-2 bg-blue-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" type="text" name="open_contract[contract_items_attributes][NEW_RECORD][serials_attributes][NEW_SERIAL][serial]" id="open_contract_contract_items_attributes_NEW_RECORD_serials_attributes_NEW_SERIAL_serial">
  </div>

  <button type="button" data-action="contract-serial-nested-form#remove" class="mt-5 inline-flex items-center h-6 px-2 border border-transparent rounded-md shadow-sm text-sm font-small text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
    x
  </button>

  <input id="serial_delete_id_new_serial" autocomplete="off" type="hidden" value="false" name="open_contract[contract_items_attributes][NEW_RECORD][serials_attributes][NEW_SERIAL][_destroy]">
</div>
</template>

<!-- Inserted elements will be injected before that target. -->
<div data-contract-serial-nested-form-target="target" id="serial_target"></div>

</div>

</div>

</template> */}





{/* <template data-contract-serial-nested-form-target="template" id="serial_template">
  
  <div class="contract-serial-nested-form-wrapper col-span-6 flex space-x-2" data-new-record="true">
  <div class="col-span-6 sm:col-span-3">
    <input class="mt-2 bg-blue-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" type="text" name="open_contract[contract_items_attributes][1690511190569][serials_attributes][NEW_SERIAL][serial]" id="open_contract_contract_items_attributes_1690511190569_serials_attributes_NEW_SERIAL_serial">
  </div>

  <button type="button" data-action="contract-serial-nested-form#remove" class="mt-5 inline-flex items-center h-6 px-2 border border-transparent rounded-md shadow-sm text-sm font-small text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
    x
  </button>

  <input id="serial_delete_id_new_serial" autocomplete="off" type="hidden" value="false" name="open_contract[contract_items_attributes][1690511190569][serials_attributes][NEW_SERIAL][_destroy]">
</div>
</template> */}