import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "quantity", "costprice", "totalcost" ]

  connect() {
    //this.element.textContent = "Hello World!"
    // console.log("Purchase Totals")
    // console.log("Do what you want here.")

    // $('#client_id').select2();
    // $('#sponsor_id').select2();
    this.update_total_values()
  }

  quantitychanged() {
    // console.log("PT Quantity changed")
    this.update_total_values()
  }

  costpricechanged() {
    // console.log("PT Cost Price changed")
    this.update_total_values()
  }

  update_total_values() {
    var prices = document.querySelectorAll("[id^=cost_price_]")
    var quantities = document.querySelectorAll("[id^=quantity_]")

    var total_quantities = 0
    var totals = 0

    for (var i = 0, len = prices.length; i < len; i++) {
      total_quantities += parseFloat(quantities[i].value)
      totals += parseFloat(prices[i].value) * parseFloat(quantities[i].value)
    }

    // for (var i = 0, len = quantities.length; i < len; i++) {
    //   total_quantities += parseFloat(quantities[i].value)
    // }

    // console.log("total_prices = " + total_prices)
    // console.log("total_quantities = " + total_quantities)
    // console.log("prices = " + prices.length)
    // console.log("quantities = " + quantities.length)
    // console.log("totals = " + totals)
    document.getElementById("total_cost").innerHTML = totals.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3})
    document.getElementById("total_quantity").innerHTML = total_quantities
  }
}
