import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ "remainingamount", "missedamount", "closeamount", "paidamount" ]

  connect() {
    // console.log("Close Contract")
    this.recalculate_missed_amount()
  }

  close_amount_changed() {
    this.recalculate_missed_amount()
  }
  
  recalculate_missed_amount()
  {
    // console.log("Recalculate Missed Amount")
    var remaining_amount = parseFloat(this.remainingamountTarget.innerHTML.replace(/,/g, ""));
    // console.log("Remaining Amount: " + remaining_amount)
    
    var paid_amount = parseFloat(this.paidamountTarget.value.replace(/,/g, ""));
    // console.log("Paid Amount: " + paid_amount)

    var close_amount = parseFloat(this.closeamountTarget.value.replace(/,/g, ""));
    // if close_amount is less than 0, set it to the same number but in positive
    if (close_amount < 0) {
      // close_amount = close_amount * -1;
      close_amount = 0;
    }
    // console.log("Close Amount: " + close_amount)

    var missed_amount = remaining_amount - close_amount;
    // console.log("Missed Amount: " + missed_amount)
    
    if (missed_amount < 0 || isNaN(close_amount)) {
      missed_amount = remaining_amount;
    }

    this.missedamountTarget.innerHTML = missed_amount.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3})
  }
}
