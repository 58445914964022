// import InvoiceNestedForm from "stimulus-rails-nested-form"

// export default class extends InvoiceNestedForm {
import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ['serial']

  search(event) {
    event.preventDefault()

    let installments = document.getElementById("contract_number_of_installments")
    
    if (installments.value == ""){ return }
    if (this.serialTarget.value == "") { return }

    let params = new URLSearchParams()
    params.append('serial', this.serialTarget.value) //event.target.selectedOptions[0].value)
    params.append('installments', installments.value) //event.target.selectedOptions[0].value)

    get(`${'/ar/search_serials'}?${params}`, {
      responseKind: "turbo-stream"
    })

  }
}