// Entry point for the build script in your package.json
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// import "chartkick/chart.js"
import "chartkick/vendor/assets/javascripts/chartkick"
require("@rails/ujs").start()
import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
require("@rails/activestorage").start()
// require("./channels")
require("local-time").start()
// import "tom-select"

//require("./stylesheets/application.css")

//import "./stylesheets/application.css"
import "./controllers"
import "./turbo_streams"

// import Chartkick from "chartkick"
// import "chartkick/chart.js"
//import "./jquery.js"
//import "./select2.js"

//import 'data-confirm-modal'

// $(document).on("turbolinks:load", () => {
  //$('[data-toggle="tooltip"]').tooltip()
  //$('[data-toggle="popover"]').popover()
// })

//import "stylesheets/application"
//import "@fortawesome/fontawesome-free/js/all"

//console.log('Hello from application.js')
//$(function() { console.log("Hello World!!") })

// Turbo.setConfirmMethod((message, element) => {
//   console.log(message, element)
//   let dialog = document.getElementById("turbo-confirm")
//   dialog.querySelector("p").textContent = message
//   dialog.showModal()

//   return new Promise((resolve, reject) => {
//     dialog.addEventListener("close", () => {
//       resolve(dialog.returnValue == "confirm")
//     }, { once: true })
//   })
// })
