import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // console.log("Hello World!")
  }

  keypressed(event) {
    //console.log("Pressed!!")
    //^[0-9]*$
    // console.log(event.key)
    if (!event.key.match("^[0-9]*$")) {
      //console.log("match!")
      event.preventDefault()
      return false
    }
  }
}
