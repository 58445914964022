import PaymentNestedForm from "stimulus-rails-nested-form"

// Connects to data-controller="payment-nested-form"
export default class extends PaymentNestedForm {
  static targets = [ "amount", "item" ]

  connect() {
    super.connect()
    // console.log("PaymentNestedForm: Do what you want here.")

    this.update_total_values()
  }

  remove(event) {
    let confirmDialog = confirm("Are you sure?")
    if (confirmDialog == false) {
      return
    }
    super.remove(event)
    this.update_total_values()
  }

  add(event) {
    super.add(event)
  }

  amountchanged() {
    // console.log("PT Amount changed")
    this.update_total_values()
  }

  update_total_values() {
    var amounts = document.querySelectorAll("[id^=amount_]")
    var deleted = document.querySelectorAll("[id^=the_hidden_field_]")

    var total_amounts = 0

    for (var i = 0, len = amounts.length; i < len; i++) {
      if (deleted[i].value != '1')
      {
        total_amounts += parseFloat(amounts[i].value)
      }
    }
    document.getElementById("total_amount").innerHTML = total_amounts.toLocaleString(undefined, {minimumFractionDigits: 3, maximumFractionDigits: 3})
  }
}