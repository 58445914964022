import { Controller } from "@hotwired/stimulus"
import TomSelect      from "tom-select"

// Connects to data-controller="tomselect"
export default class extends Controller {
  static targets = ["select"]

  config() {
    var config = {
      plugins: ['dropdown_input'],
      create: false,
      maxItems: 1,
      maxOptions: null,
      allowEmptyOption: true,
      hidePlaceholder: true,
      // openOnFocus: false
    }

    return config
  }

  connect() {
    this.selectTargets.forEach(element => {
      new TomSelect(element, this.config())
    });
  }
}
